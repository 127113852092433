import { useGetCurrentUserQuery } from 'lib/auth/api';
import Link from 'next/link';
import React from 'react';

export default function UserMenuItem() {
  const { data: user, isLoading } = useGetCurrentUserQuery();

  if (isLoading) return null;

  return (
    <>
      {user === null ? (
        <Link
          href="/session"
          className="flex items-center px-5 py-3 font-medium text-gray-600 transition duration-150 ease-in-out hover:text-gray-900"
        >
          Sign in / Sign Up
        </Link>
      ) : null}
      {user ? (
        <Link
          href="/account"
          className="flex items-center px-5 py-3 font-medium text-gray-600 transition duration-150 ease-in-out hover:text-gray-900"
        >
          {user.first_name ?? 'Account'}
        </Link>
      ) : null}
    </>
  );
}
