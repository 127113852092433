export const COMPONENT_NAME = 'Footer';

export interface IFooter {
  description?: string;
  primaryLinks?: {
    id: string;
    name: string;
    href: string;
  }[];
  secondaryLinks?: {
    id: string;
    name: string;
    href: string;
  }[];
  socialLinks?: {
    id: string;
    name: string;
    href: string;
  }[];
}
