import classNames from 'helpers/classNames';
import Link from 'next/link';
import React from 'react';
import Spinner from '../Icons/Spinner';
import { COMPONENT_NAME, IButton } from './types';

export const BUTTON_KIND = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  SECONDARY_WHITE: 'secondaryWhite',
  RED: 'red',
} as const;

export const BUTTON_SIZE = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
  XLARGE: 'xlarge',
} as const;

export const BUTTON_ICON_POSITION = {
  LEFT: 'left',
  RIGHT: 'right',
};

const Button: React.FC<IButton> = props => {
  const {
    title,
    className = '',
    disabled = false,
    external = false,
    externalBlankTarget = true,
    fullWidth = false,
    href,
    icon,
    iconPos = BUTTON_ICON_POSITION.LEFT,
    kind = BUTTON_KIND.PRIMARY,
    loading = false,
    onClick,
    type = 'button',
    size = BUTTON_SIZE.LARGE,
  } = props;
  const baseCX =
    'appearance-none rounded-full font-sans-semibold transform transition-all transition duration-300 flex flex-shrink-0 items-center border';
  const baseHoverCX = '';

  const classCX: {
    size: { [key: string]: string };
    kind: { [key: string]: { [key: string]: string } };
  } = {
    size: {
      small: 'px-4.5 py-2 text-sm leading-4',
      medium: 'px-5 py-3 text-sm leading-4',
      large: 'px-6 py-3.5 text-base leading-5 tracking-tight',
      xlarge: 'px-7 py-3.5 text-lg leading-6 tracking-tight',
    },
    kind: {
      primary: {
        base: 'text-white bg-buttonGradient border-green-500',
        focus:
          'focus:outline-none focus:border-gray-900 focus:ring-gray-900 focus:shadow-sm focus:ring-1',
        active: 'active:bg-greenGradient active:ring-0',
        disabled:
          'disabled:font-normal disabled:bg-none disabled:bg-gray-200 disabled:text-gray-500 disabled:border-gray-200',
        hover: 'hover:text-white hover:bg-greenGradient hover:no-underline',
      },
      secondary: {
        base: 'text-gray-900 bg-transparent border-gray-400',
        focus:
          'focus:outline-none focus:border-gray-800 focus:ring-gray-800 focus:shadow-sm focus:ring-1',
        active: 'active:bg-gray-100 active:ring-0 active:border-gray-500',
        disabled:
          'disabled:font-normal disabled:bg-transparent disabled:text-gray-400 disabled:border-gray-200',
        hover: 'hover:border-gray-900 hover:text-gray-900 hover:no-underline',
      },
      secondaryWhite: {
        base: 'text-white bg-transparent border-white border-opacity-70',
        focus:
          'focus:outline-none focus:border-white focus:ring-white focus:shadow-sm focus:ring-1',
        active:
          'active:bg-white active:bg-opacity-[6] active:ring-0 active:border-white',
        disabled:
          'disabled:font-normal disabled:bg-transparent disabled:text-white disabled:text-opacity-30 disabled:border-white disabled:border-opacity-10',
        hover: 'hover:bg-gray-50 hover:text-gray-900 hover:no-underline',
      },
      red: {
        base: 'text-white bg-red-500 border-red-500',
        focus:
          'focus:outline-none focus:boredr-red-700 focus:ring-red-700 focus:shadow-sm focus:ring-1',
        active: 'active:bg-red-700 active:ring-0 active:border-red-700',
        disabled:
          'disabled:font-normal disabled:bg-gray-200 disabled:text-gray-500 disabled:border-gray-200',
        hover:
          'hover:text-white hover:bg-red-700 hover:border-red-700 hover:no-underline',
      },
    },
  };

  const buttonCX = classNames(
    className,
    baseCX,
    baseHoverCX,
    fullWidth ? 'w-full' : 'w-max',
    classCX.size[`${size}`],
    classCX.kind[`${kind}`].base,
    classCX.kind[`${kind}`].disabled,
    classCX.kind[`${kind}`].focus,
    classCX.kind[`${kind}`].active,
    classCX.kind[`${kind}`].hover,
  );

  if (href && !onClick) {
    if (external) {
      const target = externalBlankTarget ? '_blank' : '_self';
      return (
        <a
          className={buttonCX}
          href={href}
          rel="noreferrer noopener nofollow"
          target={target}
        >
          {icon && iconPos === BUTTON_ICON_POSITION.LEFT && (
            <div className="mr-2">
              {React.cloneElement(icon, { className: 'h-5 w-5' })}
            </div>
          )}
          {title}
          {loading && <Spinner />}
          {icon && iconPos === BUTTON_ICON_POSITION.RIGHT && (
            <div className="ml-2">
              {React.cloneElement(icon, { className: 'h-5 w-5' })}
            </div>
          )}
        </a>
      );
    }

    return (
      <Link href={href} passHref>
        <a href={href} className={buttonCX}>
          {icon && iconPos === BUTTON_ICON_POSITION.LEFT && (
            <div className="mr-2">
              {React.cloneElement(icon, { className: 'h-5 w-5' })}
            </div>
          )}
          {title}
          {loading && <Spinner />}
          {icon && iconPos === BUTTON_ICON_POSITION.RIGHT && (
            <div className="ml-2">
              {React.cloneElement(icon, { className: 'h-5 w-5' })}
            </div>
          )}
        </a>
      </Link>
    );
  }

  return (
    <button
      className={buttonCX}
      disabled={disabled}
      onClick={onClick}
      type={type}
    >
      {icon && iconPos === BUTTON_ICON_POSITION.LEFT && (
        <div className="mr-2">
          {React.cloneElement(icon, { className: 'h-5 w-5' })}
        </div>
      )}
      {title}
      {loading && <Spinner className="ml-2" />}
      {icon && iconPos === BUTTON_ICON_POSITION.RIGHT && (
        <div className="ml-2">
          {React.cloneElement(icon, { className: 'h-5 w-5' })}
        </div>
      )}
    </button>
  );
};

Button.displayName = COMPONENT_NAME;

export default Button;
