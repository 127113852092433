import classNames from '../helpers/classNames';

export default function Wrapper(props: {
  children: React.ReactNode;
  fullWidth?: boolean;
}): JSX.Element {
  const { children, fullWidth = false } = props;

  return (
    <div
      className={classNames(
        'relative mx-auto box-content h-full px-4 md:px-10 lg:px-12',
        fullWidth ? '2xl:px-20' : ' max-w-7xl 2xl:px-0',
      )}
    >
      {children}
    </div>
  );
}
