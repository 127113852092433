import { Footer } from 'components/Footer';
import { Header } from 'components/Header';
import UserMenuItem from 'lib/session/components/UserMenuItem';
import Head from 'next/head';
import 'styles/Home.module.css';

export default function Home() {
  return (
    <div className="flex min-h-screen flex-col overflow-hidden">
      <Head>
        <title>Greener</title>
        <meta name="description" content="Generated by create next app" />
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <Header
        headerRight={
          <nav className="flex flex-grow">
            <ul className="flex flex-grow flex-wrap items-center justify-end">
              <UserMenuItem />
            </ul>
          </nav>
        }
      />

      {/*  Page content */}
      <main className="flex flex-grow items-center justify-center bg-green-50"></main>

      <Footer />
    </div>
  );
}
