import { CSSProperties } from 'react';

export const COMPONENT_NAME = 'Button';

export interface IButton {
  title: string;
  className?: string;
  disabled?: boolean;
  external?: boolean;
  externalBlankTarget?: boolean;
  fullWidth?: boolean;
  href?: string;
  icon?: JSX.Element;
  iconPos?: string;
  kind?: string;
  loading?: boolean;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  size?: string;
  style?: CSSProperties;
  type?: 'button' | 'submit' | 'reset';
}
