import { BUTTON_SIZE } from 'components/Button/Button';
import Wrapper from 'containers/Wrapper';
import Image from 'next/image';
import Logo from 'public/logo.svg';
import { useEffect, useState } from 'react';
// import classNames from "@/helpers/classNames";
// import useScrollDirection from "@/hooks/useScrollDirection";
import { isIOS, isMacOs } from 'react-device-detect';
import { Button } from '../Button';
import { COMPONENT_NAME } from './types';

function Header({ headerRight }: { headerRight?: React.ReactNode }) {
  // Has to be done this way to prevent hydration error
  // application code that is relying on something that could differ between pre-rendering and the browser.
  const [appstoreURL, setAppstoreURL] = useState('');

  useEffect(() => {
    const url =
      isIOS || isMacOs
        ? 'https://apps.apple.com/au/app/greener/id1535120978'
        : 'https://play.google.com/store/apps/details?id=com.greenr.app';
    setAppstoreURL(url);
  }, []);

  return (
    <header
      className="sticky z-10 h-[60px] w-full border-b border-black border-opacity-5 transition-all duration-300 sm:h-[84px]"
      // className={classNames(
      //   "sticky w-full border-b border-black border-opacity-5 h-[84px] transition-all duration-300 z-10",
      //   scrollDirection === "down" ? "-top-[84px]" : "top-0",
      //   lastScrollY > 0 ? "bg-white shadow-navigation" : "bg-transparent"
      // )}
    >
      <Wrapper fullWidth>
        <div className="flex h-full w-full items-center justify-between">
          <div className="relative h-[24px] w-[108px] shrink-0 sm:h-[31px] sm:w-[139px]">
            <Image layout="fill" objectFit="contain" src={Logo} alt="Greener" />
          </div>
          {headerRight ?? (
            <div className="block">
              <Button
                title="Get the app"
                href={appstoreURL}
                size={BUTTON_SIZE.MEDIUM}
                className="hidden sm:block"
              />
              <Button
                title="Get the app"
                href={appstoreURL}
                size={BUTTON_SIZE.SMALL}
                className="block sm:hidden"
              />
            </div>
          )}
        </div>
      </Wrapper>
    </header>
  );
}

Header.displayName = COMPONENT_NAME;

export default Header;
