import Wrapper from 'containers/Wrapper';
import Link from 'next/link';
import { COMPONENT_NAME } from './types';

function Footer() {
  const links = [
    {
      id: '5-1',
      name: 'Privacy',
      href: 'https://www.getgreener.com/pages/privacy-policy',
      external: true,
    },
    {
      id: '5-2',
      name: 'Terms',
      href: 'https://www.getgreener.com/pages/terms-of-use',
      external: true,
    },
    {
      id: '5-3',
      name: 'CDR policy',
      href: 'https://www.getgreener.com/pages/greener-consumer-data-right-policy',
      external: true,
    },
    {
      id: '5-4',
      name: 'Dispute Resolution',
      href: 'https://www.getgreener.com/pages/greener-internal-dispute-resolution-policy',
      external: true,
    },
  ];

  return (
    <footer className="relative w-full border-t border-black border-opacity-10 py-5 sm:py-7">
      <div>
        <Wrapper fullWidth>
          <div className="flex w-auto flex-wrap gap-x-8 gap-y-2">
            <div className="p5 inline-block text-gray-700">
              &copy; {new Date().getFullYear()} Greener
            </div>
            {links &&
              links.length > 0 &&
              links.map(item => {
                if (item.external) {
                  return (
                    <a
                      key={item.id}
                      href={item.href}
                      target="_blank"
                      rel="noreferrer"
                      className="p5 whitespace-nowrap text-gray-700"
                    >
                      {item.name}
                    </a>
                  );
                }

                return (
                  <Link key={item.id} href={item.href} passHref>
                    {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
                    <a
                      href={item.href}
                      className="p5 whitespace-nowrap text-gray-700"
                    >
                      {item.name}
                    </a>
                  </Link>
                );
              })}
          </div>
        </Wrapper>
      </div>
    </footer>
  );
}

Footer.displayName = COMPONENT_NAME;

export default Footer;
