import api from 'lib/api/api';
import { DataResponse } from 'lib/api/types';
import {
  BetaUser,
  CreateBetaUserResponse,
  CreateUserParams,
  CurrentUser,
  CurrentUserOnboarding,
  Session,
  Settings,
} from './types';

export interface ThirdPartyAuthData {
  session: {
    state: string;
  };
  url: string;
}

const currentUserApi = api.injectEndpoints({
  overrideExisting: true,
  endpoints: builder => ({
    createUser: builder.mutation<
      { user: CurrentUser } & Session,
      CreateUserParams
    >({
      query: userData => ({
        url: `/api/users`,
        method: 'post',
        body: userData,
      }),
      transformResponse: r =>
        (r as DataResponse<{ user: CurrentUser } & Session>).data,
      invalidatesTags: ['CurrentUser'],
    }),

    createBetaUser: builder.mutation<CreateBetaUserResponse, BetaUser>({
      query: userData => ({
        url: `/api/users/beta`,
        method: 'post',
        body: userData,
      }),
      invalidatesTags: ['BetaUser'],
    }),

    createSession: builder.mutation<
      Session,
      {
        email: string;
        password?: string;
        type?: string;
        totp_code?: string;
        totp_token?: string;
        aud: 'Greener' | 'Greener Business';
        device_id?: string | null;
        app_info?: { device?: string; client?: string };
      }
    >({
      query: params => ({
        url: '/api/session',
        method: 'post',
        body: params,
      }),
      invalidatesTags: ['CurrentUser'],
      transformResponse: r => (r as DataResponse<Session>).data,
    }),

    refreshSession: builder.mutation<
      Session,
      {
        device_id?: string | null;
        app_info?: { device?: string; client?: string };
      } | void
    >({
      query: params => ({
        url: '/api/session/renew',
        method: 'put',
        body: params,
      }),
      invalidatesTags: ['CurrentUser'],
      transformResponse: r => (r as DataResponse<Session>).data,
    }),

    // updateSession: builder.mutation<Session, { refreshToken: string }>({
    //   query: ({ refreshToken }) => ({
    //     url: "/api/session",
    //     method: "put",
    //     body: {
    //       refresh_token: refreshToken,
    //     },
    //   }),
    //   invalidatesTags: ["CurrentUser"],
    //   transformResponse: (r) => (r as DataResponse<Session>).data,
    // }),

    endSession: builder.mutation<void, void>({
      query: () => ({
        url: '/api/session',
        method: 'delete',
      }),
      invalidatesTags: ['CurrentUser'],
    }),

    getCurrentUser: builder.query<CurrentUser, void>({
      query: () => ({
        url: '/api/current_user',
        method: 'get',
      }),
      providesTags: ['CurrentUser'],
      transformResponse: r => (r as DataResponse<CurrentUser>).data,
    }),

    getSettings: builder.query<Settings, void>({
      query: () => ({
        url: '/api/settings',
        method: 'get',
      }),
      transformResponse: r => (r as DataResponse<Settings>).data,
    }),

    validateEmail: builder.mutation<{ ok: string }, { email: string }>({
      query: ({ email }) => {
        const searchParams = new URLSearchParams();
        searchParams.set('email', email);
        return {
          url: `/api/users?${searchParams.toString()}`,
          method: 'get',
        };
      },
    }),

    getCurrentUserOnboarding: builder.query<
      CurrentUserOnboarding,
      { accessToken?: string } | undefined
    >({
      query: params => ({
        url: '/api/current_user/onboarding',
        method: 'get',
        headers: params?.accessToken
          ? { authorization: `Bearer ${params?.accessToken}` }
          : undefined,
      }),
      providesTags: ['CurrentUserOnboarding'],
      transformResponse: r => (r as DataResponse<CurrentUserOnboarding>).data,
    }),

    updateCurrentUserOnboarding: builder.mutation<
      CurrentUserOnboarding,
      { onboarding_completed?: boolean; onboarding_permissions?: boolean }
    >({
      query: params => ({
        url: '/api/current_user/onboarding',
        method: 'PUT',
        body: params,
      }),
      invalidatesTags: ['CurrentUserOnboarding'],
      transformResponse: r => (r as DataResponse<CurrentUserOnboarding>).data,
    }),

    thirdPartyAuthorize: builder.mutation<
      ThirdPartyAuthData,
      { provider: string; redirect_uri: string }
    >({
      query: ({ provider, redirect_uri }) => ({
        url: `/api/auth/${provider}/authorize`,
        method: 'POST',
        body: {
          redirect_uri,
        },
      }),
    }),
  }),
});

export default currentUserApi;

export const {
  useGetCurrentUserQuery,
  useLazyGetCurrentUserQuery,
  useGetCurrentUserOnboardingQuery,
  useLazyGetCurrentUserOnboardingQuery,
  useUpdateCurrentUserOnboardingMutation,
  useCreateSessionMutation,
  useRefreshSessionMutation,
  useEndSessionMutation,
  useValidateEmailMutation,
  useCreateUserMutation,
  useCreateBetaUserMutation,
  useGetSettingsQuery,
  useThirdPartyAuthorizeMutation,
  // useUpdateSessionMutation,
  util: { getRunningOperationPromises },
} = currentUserApi;

export const { getCurrentUser, getSettings } = currentUserApi.endpoints;
